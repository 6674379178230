// useWxSDK.ts
import wx from 'weixin-js-sdk'
import { getSDK } from '@/service/user/auth'
import { useAuthStore } from '@/store'

// 文档 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html
// 实时该项目用到的微信sdk服务有：
// 1. 分享功能，包括分享到朋友圈和分享到好友
// 2. 获取地理位置
// 3. 打开地图
// 4. 微信扫码
// 5. 微信菜单

export function useWxSdk() {
  const wxInstance = ref(null) // 保存微信JS-SDK实例
  const error = ref(null) // 保存错误信息
  const loading = ref(false) // 保存加载状态
  const isInitialized = ref(false) // 标志JS-SDK是否已经初始化
  const currentJsApiList = ref<string[]>([]) // 当前已初始化的jsApiList
  const authStore = useAuthStore() // 获取Pinia中的用户授权信息

  /**
   * 初始化微信JS-SDK并动态添加所需的jsApiList
   * @param {string[]} requiredJsApiList - 当前页面所需的jsApiList
   */
  const initWxSdk = async (requiredJsApiList = []) => {
    // 检查当前所需的API是否已经初始化
    const missingApis = requiredJsApiList.filter((api) => !currentJsApiList.value.includes(api))
    if (isInitialized.value && missingApis.length === 0) return // 如果都已初始化，直接返回

    loading.value = true
    try {
      const url = location.href.split('#')[0]
      const res = await getSDK({ url, jsApiList: [...currentJsApiList.value, ...missingApis] })

      if (res.code !== 200) {
        throw new Error('getSDK接口异常: ' + res.message)
      }

      const data = res.data
      wx.config({
        debug: false,
        appId: import.meta.env.VITE_WX_APPID,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: [...currentJsApiList.value, ...missingApis],
      })

      return new Promise((resolve, reject) => {
        wx.ready(() => {
          wxInstance.value = wx
          loading.value = false
          isInitialized.value = true
          currentJsApiList.value = [...new Set([...currentJsApiList.value, ...missingApis])] // 更新已初始化的jsApiList
          resolve(wx)
        })

        wx.error((err) => {
          console.error('wx.error微信JS-SDK初始化失败:', err)
          error.value = err
          loading.value = false
          reject(err)
        })
      })
    } catch (err) {
      console.error('catch初始化微信JS-SDK失败:', err)
      error.value = err
      loading.value = false
    }
  }

  /**
   * 微信分享功能
   * @param {Object} options - 分享内容的配置信息
   * @param {Function} successCallback - 成功的回调函数
   * @param {Function} failCallback - 失败的回调函数
   */
  const shareContent = async (
    options = {},
    successCallback = () => {},
    failCallback = (err) => console.error(err),
  ) => {
    await initWxSdk(['updateTimelineShareData', 'updateAppMessageShareData'])
    const shareAppData = {
      title: options.title || '稳安特智慧社区',
      desc: options.desc || '稳安特智慧社区',
      link: options.link || location.href,
      imgUrl: options.imgUrl || authStore.authInfo.common.logoImg || '',
      success: () => {
        console.log('分享成功')
        successCallback()
      },
      fail: (err) => {
        console.error('分享失败', err)
        failCallback(err)
      },
    }

    const shareTimeLineData = {
      title: options.title || '稳安特智慧社区',
      link: options.link || location.href,
      imgUrl: options.imgUrl || authStore.authInfo.common.logoImg || '',
      success: () => {
        console.log('分享成功')
        successCallback()
      },
      fail: (err) => {
        console.error('分享失败', err)
        failCallback(err)
      },
    }

    wxInstance.value.updateAppMessageShareData(shareAppData)
    wxInstance.value.updateTimelineShareData(shareTimeLineData)
  }

  /**
   * 获取地理位置信息
   * @returns {Promise<Object>} 返回包含纬度和经度的地理位置信息
   */
  const getLocation = async () => {
    await initWxSdk(['getLocation'])
    return new Promise((resolve, reject) => {
      wxInstance.value.getLocation({
        type: 'gcj02',
        success: (res) => {
          const loc = {
            longitude: +res.longitude,
            latitude: +res.latitude,
          }
          // 更新Pinia中的位置
          authStore.setCurLoc(loc)
          console.log('获取位置成功', loc)
          resolve({ ...loc })
        },
        fail: (err) => {
          console.error('获取地理位置失败:', err)
          reject(err)
        },
      })
    })
  }

  /**
   * 打开腾讯地图并定位
   * @param {Object} placeInfo - 包含门店位置的配置信息
   */
  const openTencentMap = async (placeInfo) => {
    await initWxSdk(['openLocation'])
    wxInstance.value.openLocation({
      latitude: +placeInfo.latitude,
      longitude: +placeInfo.longitude,
      name: placeInfo.name,
      address: placeInfo.address,
      scale: 18,
      infoUrl: '',
    })
  }

  /**
   * 微信扫码功能
   * @param {Function} fn - 处理扫码结果的回调函数
   */
  const wxScanCode = async (fn) => {
    await initWxSdk(['scanQRCode'])
    wxInstance.value.scanQRCode({
      needResult: 1,
      scanType: ['qrCode', 'barCode'],
      success: function (res) {
        const resultStr = res.resultStr
        fn(resultStr) // 调用传入的回调函数处理扫描结果
      },
      fail: (error) => {
        console.error('扫码失败:', error)
      },
    })
  }

  /**
   * 隐藏指定的微信菜单项
   * 通过调用 initWxSdk 确保微信JS-SDK已经初始化，并确保 'hideMenuItems' API 可用。
   */
  const hideMenuItems = async () => {
    // 初始化微信JS-SDK，确保 'hideMenuItems' API 可以使用
    await initWxSdk(['hideMenuItems'])

    // 调用 wx.hideMenuItems API 隐藏菜单项
    wxInstance.value.hideMenuItems({
      menuList: [
        'menuItem:share:appMessage', // 隐藏发送给朋友的分享菜单项
        'menuItem:share:timeline', // 隐藏分享到朋友圈的分享菜单项
        'menuItem:share:qq', // 隐藏分享到QQ的分享菜单项
        'menuItem:share:weiboApp', // 隐藏分享到微博的分享菜单项
        'menuItem:share:facebook', // 隐藏分享到Facebook的分享菜单项
        'menuItem:share:QZone', // 隐藏分享到QQ空间的分享菜单项
        'menuItem:favorite', // 隐藏收藏菜单项
        'menuItem:copyUrl', // 隐藏复制链接的菜单项
      ],
    })
  }

  /**
   * 显示指定的微信菜单项
   * 通过调用 initWxSdk 确保微信JS-SDK已经初始化，并确保 'showMenuItems' API 可用。
   */
  const showMenuItems = async () => {
    // 初始化微信JS-SDK，确保 'showMenuItems' API 可以使用
    await initWxSdk(['showMenuItems'])

    // 调用 wx.showMenuItems API 显示菜单项
    wxInstance.value.showMenuItems({
      menuList: [
        'menuItem:share:appMessage', // 显示发送给朋友的分享菜单项
        'menuItem:share:timeline', // 显示分享到朋友圈的分享菜单项
        'menuItem:share:qq', // 显示分享到QQ的分享菜单项
        'menuItem:share:weiboApp', // 显示分享到微博的分享菜单项
        'menuItem:share:facebook', // 显示分享到Facebook的分享菜单项
        'menuItem:share:QZone', // 显示分享到QQ空间的分享菜单项
        'menuItem:favorite', // 显示收藏菜单项
        'menuItem:copyUrl', // 显示复制链接的菜单项
      ],
    })
  }

  return {
    wxInstance,
    initWxSdk,
    shareContent,
    getLocation,
    openTencentMap,
    wxScanCode,
    showMenuItems,
    hideMenuItems,
    error,
    loading,
  }
}
