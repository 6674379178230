import { CustomRequestOptions } from '@/interceptors/request'
import { useAdminStore, useAuthStore } from '@/store'

// 重置登录态
const resetAuthInfo = () => {
  const authStore = useAuthStore()
  const adminStore = useAdminStore()
  authStore.clearAuthInfo()
  adminStore.clearAdminInfo()

  // TODO Check登录页
  const loginRoute = '/pages/auth/Login'
  // 注意，这里的url是 '/' 开头的，如 '/pages/index/index'，跟 'pages.json' 里面的 path 不同
  const url = location.href.split('#')[1]
  const redirectRoute = `${loginRoute}?redirect=${url}`
  uni.navigateTo({ url: redirectRoute })
}
export const http = <T>(options: CustomRequestOptions) => {
  // 1. 返回 Promise 对象
  return new Promise<IResData<T>>((resolve, reject) => {
    uni.request({
      ...options,
      dataType: 'json',
      // #ifndef MP-WEIXIN
      responseType: 'json',
      // #endif
      // 响应成功
      success(res) {
        // 状态码 2xx，参考 axios 的设计
        if (res.statusCode >= 200 && res.statusCode < 300) {
          // 2.1 提取核心数据 res.data
          resolve(res.data as IResData<T>)
        } else if (res.statusCode === 401) {
          // 401错误  -> 清理用户信息，跳转到登录页
          uni.showToast({
            icon: 'none',
            title: (res.data as IResData<T>).message || '网络请求错误，请稍后重试',
          })
          // 重置登录和中台信息
          resetAuthInfo()
          reject(res)
        } else {
          // 其他错误 -> 根据后端错误信息轻提示
          !options.hideErrorToast &&
            uni.showToast({
              icon: 'none',
              title: (res.data as IResData<T>).message || '网络请求错误，请稍后重试',
            })
          reject(res)
        }
      },
      // 响应失败
      fail(err) {
        uni.showToast({
          icon: 'none',
          title: '网络错误，换个网络试试',
        })
        reject(err)
      },
    })
  })
}

/**
 * GET 请求
 * @param url 后台地址
 * @param query 请求query参数
 * @returns
 */
export const httpGet = <T>(url: string, query?: Record<string, any>) => {
  return http<T>({
    url,
    query,
    method: 'GET',
  })
}

/**
 * GET 请求
 * @param url 后台地址
 * @param header 请求头header参数
 * @param query 请求query参数
 * @returns
 */
// export const httpGetHeader = <T>(
//   url: string,
//   header?: Record<string, any>,
//   query?: Record<string, any>,
// ) => {
//   return http<T>({
//     url,
//     header,
//     query,
//     method: 'GET',
//   })
// }

/**
 * POST 请求
 * @param url 后台地址
 * @param data 请求body参数
 * @param query 请求query参数，post请求也支持query，很多微信接口都需要
 * @returns
 */
export const httpPost = <T>(
  url: string,
  data?: Record<string, any>,
  query?: Record<string, any>,
) => {
  return http<T>({
    url,
    query,
    data,
    method: 'POST',
  })
}

http.get = httpGet
http.post = httpPost
// http.getHeader = httpGetHeader
