import { getSDK } from '@/service/user/auth'
import { createPrepaymentAPI } from '@/service/user/order'
import Big from 'big.js'
import wx from 'weixin-js-sdk'
import areaData from '@/static/wat-area.json'

export const OSS_VIDEO_POSTER_URL = '?x-oss-process=video/snapshot,t_500,f_png,w_0,h_0,m_fast'
// 格式化图片路径
export const formatImageUrl = (target) => {
  const baseUrl = import.meta.env.VITE_OSS_SERVER_URL

  if (!target) return '' // 如果target为空，返回空字符串

  if (Array.isArray(target)) {
    return target.map((item) => {
      if (item.startsWith('http')) {
        return item // 如果已经是完整的URL，直接返回
      } else {
        return baseUrl + item // 否则拼接前缀
      }
    })
  } else {
    if (target.startsWith('http')) {
      return target // 如果已经是完整的URL，直接返回
    } else {
      return baseUrl + target // 否则拼接前缀
    }
  }
}

// 还原图片路径
export const unformatImageUrl = (target) => {
  const baseUrl = import.meta.env.VITE_OSS_SERVER_URL

  if (!target) return '' // 如果target为空，返回空字符串

  if (Array.isArray(target)) {
    return target.map((item) => {
      if (item.startsWith(baseUrl)) {
        return item.replace(baseUrl, '') // 如果路径包含前缀，去除前缀
      } else {
        return item // 如果已经是相对路径，直接返回
      }
    })
  } else {
    if (target.startsWith(baseUrl)) {
      return target.replace(baseUrl, '') // 如果路径包含前缀，去除前缀
    } else {
      return target // 如果已经是相对路径，直接返回
    }
  }
}

export const getOssVideoPosterUrl = (url) => {
  return formatImageUrl(url) + OSS_VIDEO_POSTER_URL
}

// 套餐编辑数据格式化函数
export const formatMealData = (data) => {
  return {
    available_date_begin: data.info?.available_date_begin || null,
    available_date_end: data.info?.available_date_end || null,
    booking: data.info?.booking,
    community_merchant_id: data.community_merchant_id,
    community_merchant_store_id: data.community_merchant_store_id,
    community_merchant_store_ids: data.community_merchant_store_ids,
    description: data.description || '',
    discount_shared: data.info?.discount_shared,
    env_picture: data.info?.env_picture || [],
    free_package: data.info?.free_package,
    id: data.id,
    main_picture: data.info?.main_picture || [],
    meal_service: data.info?.meal_service || null,
    menu_picture: data.info?.menu_picture || [],
    num_of_people: data.info?.num_of_people || null,
    original_price: data.original_price || null,
    period_of_validity: data.info?.period_of_validity,
    private_room: data.info?.private_room,
    purchase_limit: data.info?.purchase_limit || null,
    sales_date_begin: data.info?.sales_date_begin || null,
    sales_date_end: data.info?.sales_date_end || null,
    sales_price: data.sales_price,
    set_meal_category: data.set_meal_category,
    set_meal_category_id: data.set_meal_category_id,
    category_id_arr: data.category_id_arr,
    set_meal_information: data.set_meal_information,
    suitable_for_stores: data.suitable_for_stores,
    status: data.status,
    stock: data.inventory.stock,
    sub_title: data.sub_title || null,
    thumb: data.thumb,
    title: data.title,
    validity_days: data.info?.validity_days || null,
    video: data.info?.video,
    valid_date: [
      new Date(data.info?.available_date_begin).getTime(),
      new Date(data.info?.available_date_end).getTime(),
    ],
  }
}
// 将时间戳转成 "2024-04-01 21:49:19" 格式
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  const second = date.getSeconds().toString().padStart(2, '0')

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}
// 将时间戳转成 "2024-04-01" 格式
export const formatDate = (timestamp) => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

// 停车订单详情使用
// 格式化停车时间 把分钟转成 "00天00时00分" 格式
export const formatParkTime = (minutes) => {
  const days = Math.floor(minutes / (60 * 24))
  const hours = Math.floor((minutes % (60 * 24)) / 60)
  const mins = minutes % 60
  // 使用 padStart 方法补零
  const formattedDays = String(days).padStart(2, '0')
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(mins).padStart(2, '0')

  return `${formattedDays}天${formattedHours}时${formattedMinutes}分`
}
// 停车订单列表使用
// 把 parkTimeText: "39天15时28分"和parkTimeText: "3分" 统一转成 xx天xx时xx分，
export const formatParkTimeText = (parkTimeText) => {
  // 将字符串按天、时、分分割
  const daysMatch = parkTimeText.match(/(\d+)\s*天/)
  const hoursMatch = parkTimeText.match(/(\d+)\s*时/)
  const minutesMatch = parkTimeText.match(/(\d+)\s*分/)

  // 如果存在天、时或分，将其转换为数字，不存在则为0
  const days = daysMatch ? parseInt(daysMatch[1]) : 0
  const hours = hoursMatch ? parseInt(hoursMatch[1]) : 0
  const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0

  return `${days}天${hours}时${minutes}分`
}
/**
 * 通用的下单支付流程函数
 * @param {Object} createOrderParams - 创建订单的参数
 * @param {Function} createOrderAPI - 创建订单的API函数
 * @param {Function} toast - 'wot-design-uni'的toast
 * @param {Object} options - 额外的选项，如订单类型等
 * @param {Function} callback - 创建订单成功的回调函数 //todo 参数定义问题
 *
 */
export const processOrderAndPay = async (
  createOrderParams,
  createOrderAPI,
  toast,
  options = {},
  callback,
) => {
  try {
    // 创建订单
    const orderRes = await createOrderAPI(createOrderParams)
    if (orderRes.code !== 200) {
      console.error(orderRes.message)
      toast.error(orderRes.message)
      return
    }
    console.log('创建订单data:', orderRes.data)

    // 订单支付
    const prepayParams = {
      order_id: orderRes.data.id,
      payment_type: options.payment_type || 4, // 1：微信支付 4：泊链支付（默认） 5：积分兑换
      order_type: options.order_type || 1,
    }
    const payInfo = await createPrepaymentAPI(prepayParams)
    console.log('预支付', payInfo)
    if (payInfo.code !== 200) {
      toast.error(payInfo.message)
      console.error(payInfo.message)
      return
    }
    // 非积分兑换跳转支付链接
    if (options.payment_type === 5) {
    } else {
      location.href = payInfo.data.prepayment_info.payurl
    }
    // 注意 这个callback可能不需要参数
    callback(orderRes.data.id)
    // callback()
  } catch (error) {
    console.error(error)
    toast.error(error.data.message || '下单失败，请稍后重试')
  }
}
// 调用例子
// 提交订单
// const createOrder = async () => {
//   const params = { good_id: id.value, good_type: 1, count: number.value, description: remark.value }
//   await processOrderAndPay(params, createOrderAPI)
// }

// 初始化微信JS-SDK
export const initWxSDK = async (jsApiList) => {
  const url = location.href.split('#')[0]
  const res = await getSDK({ url, jsApiList })
  if (res.code !== 200) {
    throw new Error('初始化微信JS-SDK失败' + res.message)
  }
  const data = res.data
  wx.config({
    debug: false,
    appId: import.meta.env.VITE_WX_APPID,
    timestamp: data.timestamp,
    nonceStr: data.nonceStr,
    signature: data.signature,
    jsApiList: data.jsApiList,
  })
  return new Promise((resolve, reject) => {
    wx.ready(() => resolve(wx))
    wx.error((error) => {
      reject(error)
    })
  })
}

// 格式化资源组数据结构
export function formatResourceData(data) {
  const dataList = []
  // url前缀 todo判断data可否遍历
  data.artwork.forEach((item) => {
    const obj = {}
    obj.id = item.id
    obj.file_id = item.file_id
    obj.title = item.title
    obj.type = item.file.mime_type
    obj.url = item.file_full_url // icon图片的完整路径
    obj.endpoint = item.endpoint // 跳转目标的路径或链接
    obj.apply_id = item.apply_id // 跳转目标的appid
    obj.endpoint_type = item.endpoint_type // 跳转类型
    // item.attach  自定义标识
    dataList.push({ ...obj, ...item.attach })
  })
  return dataList
}

/**
 * 用于处理不同类型的资源跳转。
 *
 * @param {number} type 跳转类型：1=普通链接, 2=Uni-app内部跳转, 3=外部网页, 4=小程序重启
 * @param {string} target 目标URL，对应不同的跳转类型
 * @param {string} appId 小程序的appId，仅当 type=4 时使用
 * @param {object} [options] 可选参数配置对象
 * @param {function} [options.callback] 跳转后的回调函数，仅在 type=2 时使用
 * @param {object} [options.params] 其他附加参数，可在回调中使用
 */
export const resourceGroupJump = (
  type,
  target,
  appId,
  options = { callback: null, params: null },
) => {
  if (type !== 1 && !target) {
    console.error('跳转目标 URL 不能为空')
    return
  }

  if (type === 1) {
    // 类型1，静态资源
    console.log('静态资源')
  } else if (type === 2) {
    // 检查是否有回调函数
    if (options?.callback) {
      console.log('options.callback', options.callback)
      // 执行回调函数，并传递附加参数
      options.callback(options.params || target)
    } else {
      // 默认的页面跳转逻辑
      uni.navigateTo({
        url: target,
      })
    }
  } else if (type === 3) {
    // 跳转到外部链接
    location.href = target
  } else if (type === 4) {
    console.log('跳转小程序功能待完善')
  } else {
    console.error('未知的跳转类型')
  }
}

// 根据优惠券值和类型，返回优惠券名称
export const formatCouponValue = (value, type) => {
  const couponType = {
    1: '抵扣券',
    2: '折扣券',
    3: '免费券',
    4: '减时券',
  }
  switch (type) {
    case 1:
      return `${value}元${couponType[type]}` // 抵扣券
    case 2:
      return `${value / 10}折${couponType[type]}` // 折扣券
    case 3:
      return `${couponType[type]}` // 免费券
    case 4:
      return `${value}小时${couponType[type]}` // 减时券
    default:
      return '未知优惠券' // 处理未知类型
  }
}

/**
 * 计算商品的总价，支持折扣，并截断保留两位小数。
 *
 * @param {number} quantity - 商品数量，必须为正整数。
 * @param {number} price - 商品单价，必须为非负数。
 * @param {number} [discount=100] - 折扣值，范围为 1 到 100，例如 95 表示 9.5 折。
 * @returns {string} - 返回截断后的总价，保留两位小数。
 * @throws {Error} - 如果输入无效或折扣超出范围，抛出错误。
 */
export function calculateTotal(quantity, price, discount = 100) {
  if (isNaN(quantity) || isNaN(price) || isNaN(discount)) {
    throw new Error('无效输入：数量、价格和折扣必须是数字。')
  }

  if (discount < 1 || discount > 100) {
    throw new Error('折扣必须在 1 到 100 之间。')
  }

  const bigQuantity = new Big(quantity)
  const bigPrice = new Big(price)
  const discountMultiplier = new Big(discount).div(100)
  const discountedPrice = bigPrice.times(discountMultiplier)
  const total = bigQuantity.times(discountedPrice)

  // 截断保留两位小数，并返回格式化后的字符串 （保留两位小数）这里toFixed不参与计算，只是用来格式化小数位数
  return total.round(2, Big.roundDown).toFixed(2)
}

/**
 * 计算单个商品的总价、折扣后的总价和优惠金额，并保留两位小数。目前用于单个商品的确认订单页面的价格明细，
 * 为什加这个，因为要拿到优惠明细
 *
 * @param {number} quantity - 商品数量，必须为正整数。
 * @param {number} price - 商品单价，必须为非负数。
 * @param {number} [discount=100] - 折扣值，范围为 1 到 100，例如 95 表示 9.5 折。
 * @returns {Object} - 包含总价、折扣后的总价和优惠金额的对象，所有金额截断保留两位小数。
 * @throws {Error} - 如果输入无效或折扣超出范围，抛出错误。
 */
export function calculateItemDetails(quantity, price, discount = 100) {
  if (isNaN(quantity) || isNaN(price) || isNaN(discount)) {
    throw new Error('无效输入：数量、价格和折扣必须是数字。')
  }

  if (discount < 1 || discount > 100) {
    throw new Error('折扣必须在 1 到 100 之间。')
  }

  const bigQuantity = new Big(quantity)
  const bigPrice = new Big(price)
  const discountMultiplier = new Big(discount).div(100)

  // 计算总价和折扣后的价格
  const originalTotal = bigQuantity.times(bigPrice).round(2, Big.roundDown)
  const discountedTotal = originalTotal.times(discountMultiplier).round(2, Big.roundDown)

  // 计算优惠金额：原价 - 折扣后价
  const discountAmount = originalTotal.minus(discountedTotal)

  return {
    originalTotal: originalTotal.toFixed(2),
    discountedTotal: discountedTotal.toFixed(2),
    discountAmount: discountAmount.toFixed(2),
  }
}

/**
 * 计算总价与实付价格的差值 用于vvip计算优惠显示
 * @param {number|string} totalPrice - 总价
 * @param {number|string} paidPrice - 实付价格
 * @returns {string} 返回差值的字符串表示
 */
export function calculateDiffMinus(totalPrice, paidPrice) {
  const total = new Big(totalPrice)
  const paid = new Big(paidPrice)
  const price = total.minus(paid).toFixed(2)
  return price
}

/**
 * 获取地理区域值
 * @param {Array} keys - 地理区域编码数组
 * @returns {Array} - 对应的地理区域值
 */
export function getGeographicValues(keys) {
  const result = []
  let currentLevel = areaData['0'] // 从最高层开始

  for (const key of keys) {
    if (currentLevel[key]) {
      result.push(currentLevel[key])
      currentLevel = areaData[key] || {}
    } else {
      result.push(key)
      break
    }
  }
  return result.join('')
}

// 电话号码掩码显示
export const maskPhoneNumber = (phone) => {
  // 检查输入是否为有效的电话号码格式
  if (typeof phone === 'string' && phone.length > 4) {
    return phone.replace(/(\d{3})\d+(\d{4})/, '$1****$2')
  }
  return phone
}

// 电话号码和座机校验
export const validatePhone = (phone) => {
  // 手机号正则：以1开头，第二位为3-9，总共11位数字
  const mobilePattern = /^1[3-9]\d{9}$/
  // 座机号正则：区号以0开头，2到3位区号，7到8位号码，可以带或不带区号后面的连字符
  const landlinePattern = /^0\d{2,3}-?\d{7,8}$/

  if (phone && (mobilePattern.test(phone) || landlinePattern.test(phone))) {
    return Promise.resolve()
  } else {
    return Promise.reject('请输入有效的手机号码或座机号码')
  }
}

/**
 * 处理富文本里的图片宽度自适应
 * 1.去掉img标签里的style、width、height属性
 * 2.img标签添加style属性：max-width:100%;height:auto
 * 3.修改所有style里的width属性为max-width:100%
 * 4.去掉<br/>标签
 * @param html
 * @returns {void|string|*}
 */

export const formatRichTexts = (html) => {
  if (!html) return
  let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
    match = match.replace(/style="[^"]+"/gi, '') // .replace(/style='[^']+'/gi, '');
    match = match.replace(/width="[^"]+"/gi, '') // .replace(/width='[^']+'/gi, '');
    match = match.replace(/height="[^"]+"/gi, '') // .replace(/height='[^']+'/gi, '');

    // 图片app不支持
    // #ifdef APP-PLUS
    match = match.replace(/!*.webp/gi, '')
    // #endif

    return match
  })
  newContent = newContent.replace(/<div[^>]*>/gi, function (match, capture) {
    match = match.replace(/style="[^"]+"/gi, '') // .replace(/style='[^']+'/gi, '');
    match = match.replace(/width="[^"]+"/gi, '') // .replace(/width='[^']+'/gi, '');
    match = match.replace(/height="[^"]+"/gi, '') // .replace(/height='[^']+'/gi, '');
    return match
  })
  newContent = newContent.replace(/<p[^>]*>/gi, '')
  newContent = newContent.replace(/<[/]p[^>]*>/gi, '')
  newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
    match = match.replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi, 'width:100%;')
    return match
  })

  newContent = newContent.replace(/<br[^>]*\/>/gi, '')
  newContent = newContent.replace(/\<img/gi, '<img style="width:100%;float:left;"')
  newContent = newContent.replace(/src="\/\//gi, 'src="http://')
  // newContent = newContent.replace(/>[\s]*</gi, "><");
  // console.log(newContent);
  return newContent
}

// utils/color.js

/**
 * 调整颜色亮度的函数
 * @param {string} color - 传入的颜色值，可以是 hex 格式，例如 #FF5733
 * @param {number} amount - 调整的亮度值，正数为增亮，负数为减暗
 * @returns {string} 返回调整后的颜色 hex 值
 */
export function adjustColor(color, amount) {
  // 匹配颜色字符串中的 hex 颜色值（如果是 linear-gradient，只取其中的纯色）
  let hexColor = color.match(/#[A-Fa-f0-9]{6}|#[A-Fa-f0-9]{3}/)?.[0] || '#cccccc'

  let usePound = false // 是否带有 '#' 符号

  // 如果颜色值以 '#' 开头，去掉它以便后续处理
  if (hexColor[0] === '#') {
    hexColor = hexColor.slice(1)
    usePound = true // 记录该颜色值原本有 '#'
  }

  // 将十六进制颜色值转换为整数
  const num = parseInt(hexColor, 16)

  // 提取红、绿、蓝三种颜色分量，并根据 `amount` 进行调整
  let r = (num >> 16) + amount // 提取红色分量
  let b = ((num >> 8) & 0x00ff) + amount // 提取蓝色分量
  let g = (num & 0x0000ff) + amount // 提取绿色分量

  // 限制颜色分量的范围在 0 到 255 之间
  r = r > 255 ? 255 : r < 0 ? 0 : r
  b = b > 255 ? 255 : b < 0 ? 0 : b
  g = g > 255 ? 255 : g < 0 ? 0 : g

  // 将调整后的红、绿、蓝分量重新组合为 hex 颜色值，并返回
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')
}
