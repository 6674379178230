import { createSSRApp } from 'vue'
import App from './App.vue'
import store from './store'
// import wx from 'weixin-js-sdk'
import { routeInterceptor, requestInterceptor, prototypeInterceptor } from './interceptors'
import 'virtual:uno.css'
// import './assets/fonts/font.css'
export function createApp() {
  const app = createSSRApp(App)
  // 将微信 SDK 挂载到 Vue 实例上
  // app.config.globalProperties.$wx = wx
  app.use(store)
  app.use(routeInterceptor)
  app.use(requestInterceptor)
  app.use(prototypeInterceptor)
  return {
    app,
  }
}
