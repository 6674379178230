// stores/modules/common.js
import { defineStore } from 'pinia'
import { ref } from 'vue'

// 初始化状态
const initState = () => ({
  appConfig: {
    loginBackUrl: '',
  }, // 项目配置
  assets: {
    logoUrl: '', // Logo 静态资源
  },
})

export const useCommonStore = defineStore(
  'common',
  () => {
    const appConfig = ref(initState().appConfig)
    const assets = ref(initState().assets)

    // 设置 Logo URL
    const setLogoUrl = (val) => {
      assets.value.logoUrl = val
    }
    const setLoginBackUrl = (val) => {
      appConfig.value.loginBackUrl = val
    }

    // 重置所有状态（如需要时）
    const resetState = () => {
      const state = initState()
      appConfig.value = state.appConfig
      assets.value = state.assets
    }

    return {
      appConfig,
      assets,
      setLogoUrl,
      resetState,
      setLoginBackUrl,
    }
  },
  {
    persist: true, // 启用持久化
  },
)
