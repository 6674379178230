/**
 * 路由拦截，通常也是登录拦截
 * 可以设置路由白名单，或者黑名单，看业务需要选哪一个
 * 大部分都可以随便进入，使用黑名单，反之，使用白名单
 * 本项目中使用白名单，因为大部分页面都需要登录，所以白名单更方便
 * 作者: 菲鸽，日期: 2024-03-06
 */

import { useAuthStore } from '@/store'
import { getUnneedLoginPages, unneedLoginPages as _unneedLoginPages } from '@/utils'

// 登录页面路径
const loginRoute = '/pages/auth/Login'

// 检查用户是否已登录
const isLogined = () => {
  const userStore = useAuthStore()
  return userStore.isLogined
}
// 判断是否为开发环境
const isDev = import.meta.env.DEV

// 白名单登录拦截器 - （适用于大部分页面需要登录，少部分页面不需要登录）
const navigateToInterceptor = {
  // 注意，这里的url是 '/' 开头的，如 '/pages/index/index'，跟 'pages.json' 里面的 path 不同
  invoke({ url }: { url: string }) {
    // console.log(url) // /pages/route-interceptor/index?name=feige&age=30
    const path = url.split('?')[0]
    let unneedLoginPages: string[] = []

    // 注意  为了防止开发时出现BUG，开发环境每次获取白名单页面，生产环境只获取一次。生产环境可以移到函数外，性能更好
    if (isDev) {
      unneedLoginPages = getUnneedLoginPages()
    } else {
      unneedLoginPages = _unneedLoginPages
    }

    // 检查当前页面是否在白名单中
    const isUnneedLogin = unneedLoginPages.includes(path)
    if (isUnneedLogin) {
      return true
    }

    // 检查用户是否已登录
    const hasLogin = isLogined()
    if (hasLogin) {
      return true
    }

    // 如果用户未登录，跳转到登录页面并传递当前路径用于重定向
    const redirectRoute = `${loginRoute}?redirect=${url}`
    uni.navigateTo({ url: redirectRoute })
    return false
  },
}
// 路由拦截器插件
export const routeInterceptor = {
  install() {
    uni.addInterceptor('navigateTo', navigateToInterceptor)
    uni.addInterceptor('reLaunch', navigateToInterceptor)
    uni.addInterceptor('redirectTo', navigateToInterceptor)
    uni.addInterceptor('switchTab', navigateToInterceptor)
  },
}
