import { defineStore } from 'pinia'
import { ref } from 'vue'

const initState = () => ({
  // 用户信息
  userInfo: {},
  // token信息
  tokenInfo: {},
  // 预加载订单，用于支付页面
  preloadOrder: false,
  // 车牌信息
  plateNoList: [],
  // 当前位置坐标同意用Number类型，本项目默认中山市政府
  curLoc: {
    longitude: 113.392532, // 经度
    latitude: 22.517007, // 纬度
  },
  // 微信权限授权状态
  wxAuthStatus: {
    locationAuth: false, // 是否已经授权位置
  },
  // todo 移动到common状态库
  cart: {
    cartShowTab: 'meal', // 购物车显示的标签页
    cartCount: {
      meal: 0,
      goods: 0,
    },
  },
  common: {
    logoImg: '',
  },
})

export const useAuthStore = defineStore(
  'auth',
  () => {
    const authInfo = ref(initState())
    const isLogined = computed(() => !!authInfo.value.tokenInfo.token)

    // 设置用户信息
    const setUserInfo = (val) => {
      // authInfo.value.userInfo = val
      Object.assign(authInfo.value.userInfo, val)
    }
    // 设置token信息
    const setTokenInfo = (val) => {
      authInfo.value.tokenInfo = val
    }

    // 设置当前位置信息 存的时候必须保证经纬度是数字类型
    const setCurLoc = (val) => {
      authInfo.value.wxAuthStatus.locationAuth = true
      authInfo.value.curLoc = val
    }
    // 添加车牌号
    const addPlateNo = (plateNo) => {
      authInfo.value.plateNoList = plateNo
    }
    // 删减某个车牌号
    const removePlateNo = (plateNo) => {
      authInfo.value.plateNoList = authInfo.value.plateNoList.filter((item) => item !== plateNo)
    }
    // 购物车页面显示的选项卡
    const setCartShowTab = (val) => {
      authInfo.value.cart.cartShowTab = val
    }
    const setCartCount = (val) => {
      authInfo.value.cart.cartCount = val
    }
    // 清除登录和中台信息 //todo 优惠券的信息
    const clearAuthInfo = () => {
      authInfo.value = initState()
    }
    // 一般没有reset需求，不需要的可以删除
    const reset = () => {
      authInfo.value = initState()
    }
    // 设置预加载订单状态
    const setPreloadOrder = (val) => {
      authInfo.value.preloadOrder = val
    }
    // 设置logo url
    const setLogoImg = (val) => {
      authInfo.value.common.logoImg = val
    }
    return {
      authInfo,
      setUserInfo,
      setTokenInfo,
      setCurLoc,
      clearAuthInfo,
      addPlateNo,
      removePlateNo,
      setCartShowTab,
      setCartCount,
      isLogined,
      reset,
      setPreloadOrder,
      setLogoImg,
    }
  },
  {
    persist: true,
  },
)
