import { http } from '@/utils/http'
// 创建订单
export const createOrderAPI = (data) => {
  return http({
    url: '/member/user/order/create',
    method: 'POST',
    data,
  })
}
// 订单预支付
export const createPrepaymentAPI = (data) => {
  return http({
    url: '/member/user/payment/prepayment',
    method: 'POST',
    data,
  })
}

// 订单详情
export const getOrderDetailAPI = (query) => {
  return http.get('/member/user/order/detail', query)
}

// 获取订单列表
export const getMealOrderListAPI = (query) => {
  return http.get('/member/user/order/list', query)
}

// 取消订单
export const cancelOrderAPI = (data) => {
  return http({
    url: '/member/user/order/cancel',
    method: 'POST',
    data,
  })
}

// 订单评价
export const evaluateOrderAPI = (data) => {
  return http({
    url: '/member/user/order/evaluate',
    method: 'PUT',
    data,
  })
}
