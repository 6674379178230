/* eslint-disable no-param-reassign */
import qs from 'qs'
import { useAuthStore } from '@/store'
import { platform } from '@/utils/platform'

export type CustomRequestOptions = UniApp.RequestOptions & {
  query?: Record<string, any>
  /** 出错时是否隐藏错误提示 */
  hideErrorToast?: boolean
} & IUniUploadFileOptions // 添加uni.uploadFile参数类型

// 请求基准地址
// h5会跨域
// const baseUrl = import.meta.env.VITE_SERVER_BASEURL
// h5不会跨域
const baseUrl = import.meta.env.VITE_APP_PROXY_PREFIX

// // 拦截器配置
// const httpInterceptor = {
//   // 拦截前触发
//   invoke(options: CustomRequestOptions) {
//     // 接口请求支持通过 query 参数配置 queryString
//     if (options.query) {
//       const queryStr = qs.stringify(options.query)
//       if (options.url.includes('?')) {
//         options.url += `&${queryStr}`
//       } else {
//         options.url += `?${queryStr}`
//       }
//     }
//     // 非 http 开头需拼接地址
//     if (!options.url.startsWith('http')) {
//       options.url = baseUrl + options.url
//       // TIPS: 如果需要对接多个后端服务，也可以在这里处理，拼接成所需要的地址
//     }
//     // 1. 请求超时
//     options.timeout = 10000 // 10s
//     // 2. （可选）添加小程序端请求头标识
//     options.header = {
//       platform, // 可选，与 uniapp 定义的平台一致，告诉后台来源
//       ...options.header,
//     }
//     // 3. 添加 token 请求头标识
//     const authStore = useAuthStore()
//     const { token } = authStore.authInfo.tokenInfo as any
//     if (token) {
//       // 注意 现在只要登登录了，请求就会有token
//       options.header.Authorization = `Bearer ${token}`
//     }
//   },
// }

// 拦截器配置
const httpInterceptor = {
  // 拦截前触发
  invoke(options: CustomRequestOptions) {
    // 接口请求支持通过 query 参数配置 queryString
    if (options.query) {
      const queryStr = qs.stringify(options.query)
      if (options.url.includes('?')) {
        options.url += `&${queryStr}`
      } else {
        options.url += `?${queryStr}`
      }
    }

    // 非 http 开头需拼接地址
    if (!options.url.startsWith('http')) {
      options.url = baseUrl + options.url
    }

    // 判断是否是文件上传
    const isUploadFile = !!options.files || !!options.filePath

    // 根据是否为上传文件请求设置不同的超时时间
    if (isUploadFile) {
      // 文件上传请求设置较长的超时时间，
      options.timeout = 50000 // 50s
    } else {
      // 普通请求设置较短的超时时间，
      options.timeout = 10000 // 10s
    }

    // 添加小程序端请求头标识
    options.header = {
      platform, // 可选，与 uniapp 定义的平台一致，告诉后台来源
      ...options.header,
    }

    // 添加 token 请求头标识
    const authStore = useAuthStore()
    const { token } = authStore.authInfo.tokenInfo as any
    if (token) {
      options.header.Authorization = `Bearer ${token}`
    }
  },
}

export const requestInterceptor = {
  install() {
    // 拦截 request 请求
    uni.addInterceptor('request', httpInterceptor)
    // 拦截 uploadFile 文件上传
    uni.addInterceptor('uploadFile', httpInterceptor)
  },
}
