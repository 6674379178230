import { defineStore } from 'pinia'
import { ref } from 'vue'
// 租房周边信息状态
export const useHouseMapStore = defineStore(
  'houseMap',
  () => {
    const state = ref({
      houses: {}, // { houseId: { poiDataByCategory: {}, activeCategory: '', selectedPoi: {} } }
      activeHouseId: null, // 当前选中的房子 ID
    })

    // 设置某个房子的周边信息
    const setPoiDataForHouse = (houseId, category, poiList) => {
      if (!state.value.houses[houseId]) {
        state.value.houses[houseId] = {
          poiDataByCategory: {},
          activeCategory: null,
          selectedPoi: null,
        }
      }
      state.value.houses[houseId].poiDataByCategory[category] = poiList
    }

    // 设置当前激活的房子
    const setActiveHouse = (houseId) => {
      state.value.activeHouseId = houseId
    }

    // 获取当前房子的状态
    const getActiveHouse = () => {
      return state.value.houses[state.value.activeHouseId] || null
    }

    // 获取当前房子的某个分类的周边信息
    const getActivePoiList = () => {
      const activeHouse = getActiveHouse()
      if (activeHouse && activeHouse.activeCategory) {
        return activeHouse.poiDataByCategory[activeHouse.activeCategory] || []
      }
      return []
    }

    // 设置当前房子激活的分类
    const setActiveCategoryForHouse = (category) => {
      const activeHouse = getActiveHouse()
      if (activeHouse) {
        activeHouse.activeCategory = category
      }
    }

    // 获取某房子某分类的周边数据（如果不存在，则从接口获取）
    const fetchPoiDataForHouse = async (houseId, category, fetchPoiData) => {
      const house = state.value.houses[houseId]
      if (house?.poiDataByCategory[category]?.length) {
        // 如果有缓存数据，直接返回
        return house.poiDataByCategory[category]
      }

      // 否则从接口获取
      const poiList = await fetchPoiData(category)
      setPoiDataForHouse(houseId, category, poiList) // 保存到 Pinia
      return poiList
    }
    // 获取当前房子的活跃类
    const getActiveCategory = () => {
      const activeHouse = getActiveHouse()
      return activeHouse?.activeCategory || null
    }

    return {
      state,
      setPoiDataForHouse,
      setActiveHouse,
      getActiveHouse,
      getActivePoiList,
      setActiveCategoryForHouse,
      getActiveCategory,
      fetchPoiDataForHouse,
    }
  },
  {
    persist: true,
  },
)
